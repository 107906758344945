






















































































































































































































































































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import { plato_ficha } from "@/shared/dtos/visualizacion_dieta/plato_ficha";
import { RouterNames } from "@/router/routernames";
import { alimento_cantidad_ficha_platoDto } from "@/shared/dtos/visualizacion_dieta/alimento_cantidad_ficha_platoDto";
@Component({
  components: {
    ViewDataEstructuraBasica: () =>
      import(
        "@/views/entrevista/dieta-visualizacion/view_data_estructura_basica.vue"
      ),
  },
})
export default class ViewDataPlato extends Vue {
  @PropSync("dt", { type: Object }) syncdt!: dieta_visualizacion;
  public dato: any = {};
  public platos: plato_ficha[] = [];
  public tab = 1;

  public async created() {
    await nueva_platoModule.getnueva_plato_ficha(this.syncdt.id_estructura);
    for (let i = 0; i < nueva_platoModule.nueva_plato_ficha.length; i++) {
      nueva_platoModule.nueva_plato_ficha[i].display_text_combo =
        nueva_platoModule.nueva_plato_ficha[i].talla.toString() +
        " (" +
        nueva_platoModule.nueva_plato_ficha[i].energia +
        " Kcal)";
    }

    this.platos = nueva_platoModule.nueva_plato_ficha;
    this.dato = {
      id_estructura: this.platoInicio.id_estructura,
      display_text_combo: this.platoInicio.display_text_combo,
    };
  }

  public get plato(): plato_ficha {
    let x = nueva_platoModule.nueva_plato_ficha.filter(
      (x) => x.id_estructura === this.dato.id_estructura
    )[0];
    return x;
  }

  public get platoInicio(): plato_ficha {
    let x = nueva_platoModule.nueva_plato_ficha.filter(
      (x) => x.id_estructura === this.syncdt.id_estructura
    )[0];
    return x;
  }

  @Watch("dato")
  public changed() {
    this.syncdt.id_estructura = this.dato.id_estructura;
  }

  public edit_plato() {
    this.$router.push({
      name: RouterNames.platosficha,
      params: { id: this.plato.id_estructura.toString() },
    });
  }

  public edit_alimento(ali: alimento_cantidad_ficha_platoDto) {
    this.$router.push({
      name: RouterNames.alimentoficha,
      params: { id: ali.id_estructura_alimento.toString() },
    });
  }
}
